<template>
  <app-side-menu>
    <aside class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Filters
        </span>
      </p>
      <ul class="menu-list">
        <li>
          <div class="field">
            <div class="control">
              <input class="input is-capitalized"
                :class="[`input-${$user.info.theme}`]"
                type="text"
                placeholder="Quote No"
                v-model="innerValue.quoteNo"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <v-date-picker v-model="selectedFromDate"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale"
              :model-config="{ timeAdjust: '00:00:00' }"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      :class="[`input-${$user.info.theme}`]"
                      placeholder="Quote Date From..."
                      :value="inputValue"
                      v-on="inputEvents">
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
          <div class="field">
            <v-date-picker
              v-model="selectedToDate"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale"
              :model-config="{ timeAdjust: '23:59:59' }"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input"
                      :class="[`input-${$user.info.theme}`]"
                      placeholder="Quote Date To..."
                      :value="inputValue"
                      v-on="inputEvents">
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
          <div class="field">
            <div class="control">
              <input class="input is-capitalized"
                :class="[`input-${$user.info.theme}`]"
                type="text"
                placeholder="Item No"
                v-model="innerValue.itemNo"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input is-capitalized"
                :class="[`input-${$user.info.theme}`]"
                type="text"
                placeholder="Item Desc"
                v-model="innerValue.itemDescription"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input is-capitalized"
                :class="[`input-${$user.info.theme}`]"
                type="text"
                placeholder="PO No"
                v-model="innerValue.poNo"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input is-capitalized"
                :class="[`input-${$user.info.theme}`]"
                type="text"
                placeholder="Vendor Name"
                v-model="innerValue.vendor"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
        </li>
      </ul>
      <hr>
      <div class="field is-grouped is-pulled-right">
        <div class="control">
          <button class="button is-primary"
            @click="onFilterClick">
            <span class="icon">
              <i class="mdi mdi-magnify mdi-18px" />
            </span>
            <span>Filter</span>
          </button>
        </div>
        <div class="control">
          <button class="button"
            @click="onResetClick">
            <span class="icon">
              <i class="mdi mdi-refresh mdi-18px" />
            </span>
            <span>Reset</span>
          </button>
        </div>
      </div>

    </aside>
  </app-side-menu>
</template>
<script>
import AppSideMenu from '@/components/AppSideMenu'
import { mask } from 'vue-the-mask'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'UsedpartsinfoListSideMenu',
  inject: ['$vv'],
  components: {
    AppSideMenu
  },
  directives: {
    mask
  },
  props: {
    filter: null,
    value: null
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      selectedActive: 1,
      stateList: null,
      selectedFromDate: null,
      selectedToDate: null,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    }
  },
  watch: {
    selectedFromDate: function (newVal, oldVal) {
      if (newVal) {
        this.innerValue.dateFrom = this.selectedFromDate.toISOString()
      } else {
        this.innerValue.dateFrom = ''
      }
    },
    selectedToDate: function (newVal, oldVal) {
      if (newVal) {
        this.innerValue.dateTo = this.selectedToDate.toISOString()
      } else {
        this.innerValue.dateTo = ''
      }
    },
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    value: {
      handler: function (newVal, oldVal) {
        this.innerValue = this.value
      },
      deep: true
    }
  },
  created() {
    if (this.innerValue.dateFrom) {
      this.dateFromFilter = new Date(this.innerValue.dateFrom).toLocaleDateString(this.$userInfo.locale)
      this.selectedFromDate = new Date(this.value.dateFrom)
    }
    if (this.innerValue.dateTo) {
      this.dateToFilter = new Date(this.innerValue.dateTo).toLocaleDateString(this.$userInfo.locale)
      this.selectedToDate = new Date(this.value.dateTo)
    }
  },
  mounted() {},
  beforeDestroy() {
    const elements = document.getElementsByClassName('datepicker')
    Array.from(elements).forEach((element, index, array) => {
      const parent = element.parentNode
      // console.log(parent.nodeType, element)
      if (parent.nodeName === 'BODY') {
        parent.removeChild(element)
      }
    })
    // while (elements.length > 0) {
    //   const parent = elements[elements.length - 1].parentNode
    //   console.log(parent, elements[elements.length - 1])
    //   parent.removeChild(elements[elements.length - 1])
    // }
  },
  methods: {
    onFilterClick() {
      this.innerValue.dateFrom = this.selectedFromDate !== null ? this.selectedFromDate.toISOString() : ''
      this.innerValue.dateTo = this.selectedToDate !== null ? this.selectedToDate.toISOString() : ''
      this.$emit('filter')
    },
    onResetClick() {
      this.selectedFromDate = null
      this.selectedToDate = null
      this.$emit('reset')
    },
    hide(event) {
      if (!event.relatedTarget || (event.relatedTarget.classList && !event.relatedTarget.classList.contains('date-item'))) {
        // this.fromDatePicker.hide()
        // this.toDatePicker.hide()
      }
    },
    formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getUTCMonth() + 1),
        day = '' + (d.getUTCDay() + 1),
        year = d.getUTCFullYear()

      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day
      return year, month, day
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/mixins/inputs/placeholder';
input.uppercase {
  text-transform: uppercase;
  @include reset-placeholder-text-transform;
}
</style>
